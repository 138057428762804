import React from "react";
import styled from "styled-components";

import StyledAvatar from "../Avatar";
import StyledSocialMediaIcons from "../SocialMediaIcons";

const UserProfile = ({ className }) => {
  const classPrefix = "user-profile";
  return (
    <div className={`${className} user-profile__wrapper`}>
      <div className={`${classPrefix}__avatar`}>
        <StyledAvatar></StyledAvatar>
      </div>
      <div className={`${classPrefix}__bio`}>
        <div className={`${classPrefix}__intro`}>Elle Hallal</div>
        <div className={`${classPrefix}__pronouns`}>(she / her)</div>
        <div>
          Software Crafter at{" "}
          <a
            href="https://8thlight.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            8th Light
          </a>
          , and Leadership Team Member and Mentor at{" "}
          <a
            href="https://codingblackfemales.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Coding Black Females
          </a>
          .
          <br />
          <br />
          <StyledSocialMediaIcons />
          <div className={`${classPrefix}__disclaimer`}>
            Note: views expressed do not represent any associated organisations.
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

const StyledUserProfile = styled(UserProfile)`
  border-width: 1px solid ${props => props.theme.color.darkGrey};
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  -moz-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  background-color: ${props => props.theme.color.cream};
  padding: 0.5em 2em;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a {
    color: ${props => props.theme.color.orange};
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: ${props => props.theme.color.defaultText};
      background-color: ${props => props.theme.color.lighterGrey};
      border-radius: 4px;
    }
  }

  .user-profile__avatar {
    align-self: center;
  }

  .user-profile__bio {
    padding-bottom: 1.5em;
  }

  .user-profile__intro {
    font-size: 2em;
    font-weight: 700;
  }

  .user-profile__pronouns {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid ${props => props.theme.color.lightGrey};
  }

  .user-profile__disclaimer {
    font-style: italic;
    margin-top: 0.5em;
    font-size: 0.8em;
  }

  .social-media-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .twitter-follow-button,
  .github-follow-button {
    padding-right: 0.5em;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    text-align: center;
    
    img {
      width: 30vw;
      max-width: 40vw;
      min-width: 20vw;
      height auto;
    }

    .user-profile__follow-buttons {
      justify-content: center;
    }

    .social-media-icons {
      justify-content: center;
    }
  }
`;

export default StyledUserProfile;
