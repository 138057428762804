import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
const slugify = require("slugify");

const TagList = ({ tags, showIcon = true, className }) => {
  return (
    <TagsWrapper className={className}>
      {tags && tags.length > 0 && (
        <ListOfTags>
          {showIcon && (
            <FontAwesomeIcon
              alt="tag icon"
              icon={["fas", "tags"]}
              size="sm"
              fixedWidth
            />
          )}
          {tags.map(tag => (
            <Tag key={tag}>
              <Link key={tag} to={`/tags/${slugify(tag)}`}>
                {tag}
              </Link>
            </Tag>
          ))}
        </ListOfTags>
      )}
    </TagsWrapper>
  );
};

const TagsWrapper = styled.div``;

const ListOfTags = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Tag = styled.li`
  display: inline-block;
  background-color: ${props => props.theme.color.orange};
  padding: 0.1em 0.3em;
  margin: 0.3em 0.3em;
  border-radius: 0.4em;
  :first-child:before {
    display: none;
  }

  a {
    text-decoration: none;
    font-size: 0.9em;
    font-weight: bold;
    color: ${props => props.theme.color.white} !important;
    &:active,
    &:visited {
      color: ${props => props.theme.color.white};
    }
    &:hover {
      color: ${props => props.theme.color.black} !important;
    }
  }
`;

export default TagList;
