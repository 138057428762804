import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const SmallPostBlock = ({ icon, label, postLink, postTitle, className }) => {
  return (
    <div className={`${className} small-post-block`}>
      <div className={"small-post-block__label"}>{label}</div>
      <Link to={postLink}>
        <div className={"small-post-block__post-title"}>{postTitle}</div>
      </Link>
    </div>
  );
};

const StyledSmallPostBlock = styled(SmallPostBlock)`
  min-width: 10em;
  max-width: 40%;

  .small-post-block__label {
    font-size: 1.2em;
    font-weight: 900;
  }

  .small-post-block__post-title {
    margin-top: 0.8em;
    text-decoration: none;
  }
`;

export default StyledSmallPostBlock;
