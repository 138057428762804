import { graphql } from "gatsby";
import React from "react";
import Post from "../components/Post";
import Layout from "../components/Layout";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

export default ({ data, pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { frontmatter, fields } = data.mdx;
  const { title } = frontmatter;

  return (
    <Layout title={title} siteUrl={siteUrl} slug={fields.slug}>
      <Post data={data} pageContext={pageContext} />
    </Layout>
  );
};

export const query = graphql`
  query POSTS_BY_SLUG($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        description
        cover {
          publicURL
        }
        tags
      }
      body
      excerpt
      fields {
        slug
      }
      timeToRead
    }
  }
`;
