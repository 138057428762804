import React from "react";
import { DiscussionEmbed as DisqusComments } from "disqus-react";
import styled from "styled-components";

import { useSiteMetadata } from "../../../hooks/useSiteMetadata";

const Comments = ({ data, className }) => {
  const { siteUrl } = useSiteMetadata();
  const { fields, frontmatter, id } = data.mdx;
  const { title } = frontmatter;

  const disqusShortname = "ellehallal";
  const disqusConfig = {
    identifier: id,
    title: title,
    url: `${siteUrl + fields.slug}`
  };

  return (
    <React.Fragment>
      <div className={className}>
        <h3>Discussion</h3>
      </div>
      <DisqusComments shortname={disqusShortname} config={disqusConfig} />
    </React.Fragment>
  );
};

const StyledComments = styled(Comments)`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  font-size: 1.3rem;
`;

export default StyledComments;
