import React from "react";
import styled from "styled-components";

const PostImage = ({ imageURL = null, boxShadow = true, className }) => {
  const addBoxShadow = boxShadow ? "add-box-shadow" : "";

  return (
    <div className={`${className} ${addBoxShadow}`}>
      {typeof imageURL === "string" && imageURL.length > 0 && (
        <div className={"post-image"} />
      )}
    </div>
  );
};

const StyledPostImage = styled(PostImage)`
  .post-image {
    background-image: url(${props => props.imageURL});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: auto;
    height: 35vh;
    border-radius: 10px;
  }

  .add-box-shadow {
    -webkit-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
    -moz-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
    box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  }
`;

export default StyledPostImage;
