import React from "react";
import { ShareButtonCircle, ShareBlockStandard } from "react-custom-share";
import { FaTwitter, FaFacebook, FaEnvelope, FaLinkedin } from "react-icons/fa";
import styled from "styled-components";

const SocialShareBlock = ({ text, url, title, excerpt, className }) => {
  const shareBlockProps = {
    url: url,
    button: ShareButtonCircle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "Linkedin", icon: FaLinkedin },
      { network: "Email", icon: FaEnvelope }
    ],
    text: `${title} by Elle Hallal - `,
    longtext: excerpt
  };

  return (
    <div className={`${className} share-block`}>
      <div className="share-block__label">{text}</div>
      <ShareBlockStandard {...shareBlockProps} />
    </div>
  );
};

const StyledSocialShareBlock = styled(SocialShareBlock)`
  text-align: center;
  padding: 0.75em 0;
  margin-bottom: 1em;
  border-bottom: 1px solid ${props => props.theme.color.darkGrey};
  border-top: 1px solid ${props => props.theme.color.darkGrey};

  .share-block__label {
    font-weight: 600;
    font-size: 1.1em;
  }
`;

export default StyledSocialShareBlock;
