import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import SEO from "react-seo-component";
import styled from "styled-components";

import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import TagList from "../../shared/components/TagList";
import StyledComments from "../../shared/components/Comments";
import StyledSocialShareBlock from "../../shared/components/SocialShareBlock";
import UserProfile from "../../shared/components/UserProfile";
import StyledSmallPostBlock from "../../shared/components/SmallPostBlock";
import StyledTitle from "../../shared/components/Title";
import { mediumDateFormatter } from "../../shared/utils/dateFormatter";
import { toTitleCase } from "../../shared/utils/toTitleCase";
import StyledPostImage from "../../shared/components/PostImage";
import { getAccurateTimeToRead } from "../../shared/utils/getAccurateTimeToRead";

const Post = ({ data, pageContext }) => {
  const {
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();
  const classPrefix = "post";
  const { frontmatter, body, fields, excerpt, timeToRead } = data.mdx;
  const { title, date, cover } = frontmatter;
  const { previous, next } = pageContext;

  return (
    <PostContainer>
      <SEO
        title={title}
        description={excerpt}
        image={
          cover ? `${siteUrl}${cover.publicURL}` : `${siteUrl}${image}`
        }
        pathname={siteUrl + fields.slug}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        article={true}
        publishedDate={date}
        modifiedDate={new Date(Date.now()).toISOString()}
      />
      <StyledPostImage
        imageURL={cover && cover.publicURL}
        postTitle={toTitleCase(title)}
      />
      <PostHeader>
        <Title label={toTitleCase(title)} />
        <StyledTagList tags={frontmatter.tags} />
        <hr />
        <div className={`${classPrefix}__header-time-date`}>
          <div className={`${classPrefix}__published_date`}>
            {mediumDateFormatter(date)}
          </div>
          <div className={`${classPrefix}__read-time`}>
            {getAccurateTimeToRead(timeToRead)} min read
          </div>
        </div>
      </PostHeader>
      <div className={`${classPrefix}__body`}>
        <MDXRenderer imageURL={cover && cover.publicURL}>{body}</MDXRenderer>
      </div>
      <StyledSocialShareBlock
        text={`If you enjoyed reading "${toTitleCase(
          title
        )}", please consider sharing it:`}
        url={siteUrl + fields.slug}
        title={toTitleCase(title)}
        excerpt={excerpt}
      />

      <PreviousNextContainer>
        {getPreviousPost(previous)}
        {getNextPost(next)}
      </PreviousNextContainer>
      <UserProfile />

      <StyledComments data={data} />
    </PostContainer>
  );
};

const getPreviousPost = (previous) => {
  return previous ? (
    <React.Fragment>
      {previous && (
        <StyledSmallPostBlock
          label={"Previous post:"}
          postLink={previous.fields.slug}
          postTitle={toTitleCase(previous.frontmatter.title)}
          className={"post__previous"}
        />
      )}
    </React.Fragment>
  ) : null;
};

const getNextPost = (next) => {
  return next ? (
    <React.Fragment>
      {next && (
        <StyledSmallPostBlock
          label={"Next post:"}
          postLink={next.fields.slug}
          postTitle={toTitleCase(next.frontmatter.title)}
          className={"post__next"}
        />
      )}
    </React.Fragment>
  ) : null;
};

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  margin: 0 auto;
  width: 50%;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 65%;
  }

  .post__body {
    a {
      color: ${(props) => props.theme.color.orange};
      text-decoration: none;
      font-weight: 700;

      &:hover {
        color: ${(props) => props.theme.color.defaultText};
        background-color: ${(props) => props.theme.color.lighterGrey};
        border-radius: 4px;
      }
    }
  }
`;

const PostHeader = styled.div`
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  -moz-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  padding: 1em;
  margin-top: 0;
  text-align: center;
  background-color: ${(props) => props.theme.color.cream};

  .post__header-time-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .post__published_date,
  .post__updated_date,
  .post__read-time {
    font-weight: 700;
    font-size: 0.8em;
    margin: 0 1em;
  }
`;

const StyledTagList = styled(TagList)`
  margin-top: 0.8em;
  font-size: 0.9em;
`;

const PreviousNextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0;
  flex-wrap: wrap;
  padding: 1em;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  -moz-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  padding: 1em;
  text-align: center;
  background-color: ${(props) => props.theme.color.cream};
  margin-bottom: 1.2em;

  a {
    color: ${(props) => props.theme.color.orange};
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: ${(props) => props.theme.color.defaultText};
      background-color: ${(props) => props.theme.color.lighterGrey};
      border-radius: 4px;
    }
  }

  @media (max-width: 860px) {
    justify-content: center;
    flex-wrap: wrap;

    .post__previous,
    .post__next {
      margin: 0.5em;
    }
  }
`;

const Title = styled(StyledTitle)`
  font-weight: 900;
  font-size: 2.6em;
  margin-bottom: 0.5em;
  margin-top: 0.2em;

  @media (max-width: 768px) {
    font-size: 1.6em;
  }

  @media (max-width: 450px) {
    font-size: 1.4em;
    word-wrap: normal;
  }
`;

export default Post;
